import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
let Breadcrumb = class Breadcrumb extends Vue {
    constructor() {
        super(...arguments);
        this.items = [];
    }
    getParentItems(parent) {
        const items = [];
        if (parent) {
            items.push(...this.getParentItems(parent.parent));
            if (parent.slug === '/') {
                return items;
            }
            items.push({
                title: parent.name,
                path: parent.slug
            });
        }
        return items;
    }
};
__decorate([
    Inject(SiteServiceType)
], Breadcrumb.prototype, "siteService", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    })
], Breadcrumb.prototype, "content", void 0);
Breadcrumb = __decorate([
    Component({
        created() {
            this.items = [
                {
                    title: this.$t('modules.content.breadcrumb.home').toString(),
                    path: '/'
                },
                ...this.getParentItems(this.content.parent),
                {
                    title: this.content.title,
                    path: this.content.properties.canonicalUrl
                }
            ];
        }
    })
], Breadcrumb);
export { Breadcrumb };
export default Breadcrumb;
