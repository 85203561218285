var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[(_vm.siteMetadata)?_c('script',{tag:"component",attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(JSON.stringify(_vm.siteMetadata))}}):_vm._e(),(_vm.slots[_vm.LayoutSlot.Top])?_c('div',{class:_vm.getContainerClass(_vm.LayoutContainer.Top)},[_c('PageBuilder',_vm._b({attrs:{"name":"page-builder-top","parent":_vm.content,"tag":"div"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Top], {relatedService: _vm.relatedService}),false))],1):_vm._e(),_c('div',{class:_vm.getContainerClass(_vm.LayoutContainer.Middle)},[_c('div',{staticClass:"row",class:{'justify-content-md-center':_vm.isPremiumZone}},[_c('article',{ref:"articleContent",staticClass:"col-12 article-content",class:{ 'col-lg-8': !_vm.isPremiumZone }},[(_vm.isPremiumZone)?[(_vm.articleTeaserModule)?_c('PageBuilder',_vm._b({attrs:{"parent":_vm.content,"tag":"div"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Main],
                     {modules: [_vm.articleTeaserModule], relatedService: _vm.relatedService}),false)):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[(_vm.modulesWithoutArticleTeaser)?_c('PageBuilder',_vm._b({attrs:{"parent":_vm.content,"tag":"main"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Main],
                               {modules: _vm.modulesWithoutArticleTeaser,
                               relatedService: _vm.relatedService}),false)):_vm._e(),(_vm.source)?_c('ArticleSource',_vm._b({},'ArticleSource',{ source: _vm.source },false)):_vm._e(),(_vm.tags && _vm.tags.length)?_c('TagsList',_vm._b({},'TagsList',{ relatedService: _vm.relatedService, tags: _vm.tags },false)):_vm._e(),(_vm.showComments)?_c('Comments',{attrs:{"parent":_vm.content}}):_vm._e()],1)])]:[(_vm.slots[_vm.LayoutSlot.Main])?_c('PageBuilder',_vm._b({attrs:{"parent":_vm.content,"tag":"main"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Main], {relatedService: _vm.relatedService}),false)):_vm._e(),(_vm.source)?_c('ArticleSource',_vm._b({},'ArticleSource',{ source: _vm.source },false)):_vm._e(),(_vm.hasPaywall)?_c('Paywall',{attrs:{"mode":_vm.result.paywall,"page":_vm.content},on:{"unlock":_vm.unlockArticle}}):_vm._e(),(_vm.tags && _vm.tags.length)?_c('TagsList',_vm._b({},'TagsList',{ relatedService: _vm.relatedService, tags: _vm.tags },false)):_vm._e(),(_vm.adAboveCommentsModules)?_c('PageBuilder',_vm._b({attrs:{"parent":_vm.content,"name":"page-builder-above-comments"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Comments],
                     {modules: [].concat( _vm.adAboveCommentsModules ), relatedService: _vm.relatedService}),false)):_vm._e(),(_vm.showComments)?_c('Comments',{attrs:{"parent":_vm.content}}):_vm._e(),(_vm.adBelowCommentsModules)?_c('PageBuilder',_vm._b({attrs:{"parent":_vm.content,"name":"page-builder-below-comments"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Comments],
                     {modules: [].concat( _vm.adBelowCommentsModules ), relatedService: _vm.relatedService}),false)):_vm._e(),_c('div',{attrs:{"id":"taboola-below-comments"}})]],2),(!_vm.isPremiumZone && _vm.slots && _vm.slots[_vm.LayoutSlot.Right])?_c('StickySidebar',{staticClass:"col-12 col-lg-3 offset-lg-1",attrs:{"slotConfig":_vm.slots[_vm.LayoutSlot.Right]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var columns = ref.columns;
                     var modules = ref.modules;
                     var tag = ref.tag;
return [(modules)?_c('PageBuilder',_vm._b({attrs:{"name":"page-builder-right","parent":_vm.content,"tag":"aside"}},'PageBuilder',{ columns: columns, modules: modules, tag: tag, relatedService: _vm.relatedService },false)):_vm._e()]}}],null,false,3939848718)}):_vm._e()],1)]),(_vm.slots[_vm.LayoutSlot.Bottom])?_c('div',{class:_vm.getContainerClass(_vm.LayoutContainer.Bottom)},[_c('div',{staticClass:"row",class:{'justify-content-md-center':_vm.isPremiumZone}},[_c('div',{staticClass:"col"},[_c('PageBuilder',_vm._b({attrs:{"name":"page-builder-bottom","parent":_vm.content,"tag":"div"}},'PageBuilder',Object.assign({}, _vm.slots[_vm.LayoutSlot.Bottom], {relatedService: _vm.relatedService}),false))],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }